.Header{
  margin-top: 0%;

  width : 100%;
  height: 12vh;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  background-color: #fce04c;

}

.Logo{
  margin-left: 0%;
  margin-top: auto;
  margin-bottom: auto;
  width: 30%;

  height: 100%;

}

.Logo img{
   
   width: 30vw;
   max-width: 200px;
   height: 100%;

}

.Header_Right{
	display: flex;
	flex-direction: row;
	margin-left: auto;
}

.Header_Left{
	background-color: transparent;
	
	flex-direction: row;
	display: none;
}

.DesktopDisplay{
	display: flex;
	height: 100%;
}

.mobileMenu{
	display: none;
	padding-bottom: .7vh;
}



@media screen and (max-width: 560px){
	.Header{
		max-height: 60px;
	}

	.Header_Right{
		width : 70%;
	}
	.mobileMenu{
		display: flex;
		background-color: #fce04c;
		padding-top: .5vh;
	}
	
	.DesktopDisplay{
		display: none;
	}
}


.Promise{
	background-color: #fce04c;
	color: black;
	font-size: 60;
	padding-top: 2vw;
	padding-bottom: 2vw;
	text-align: center;
	

}


.Header_Text{
	margin :auto;
	height: 100%;
}

.MenuButton{
	margin:auto;
	padding: .5vw;
	size:2vh;
	background-color: transparent;
	border : none;
	font-size: 1em;
	height: 100%;

}



.MenuButton:hover{
box-shadow: 0px 1px 0px 0px;
color : grey;
}

@media screen and (min-width: 950px){
	.DesktopDisplay{

	}
	.MenuButton{
		margin-left: 2vw;
		margin-right: 2vw;
	}
}

.footer{
	margin-top: auto;
	min-height: 10vh;
	background-color: grey;
	display: flex;
	flex-direction: row;
}

.footer1{
	width: 52.5%;
	display: flex;
	flex-direction: row;
}
.logo-footer-container{
	
	margin-left: 2.5%;
	margin-top : auto;
	margin-bottom: auto;
	
	width: 38%;	
	text-align: center;


}

.logo-footer-container img{
	width: 50%;
	margin:auto;
}

.logo-footer{
	display: flex;
	flex-direction: row;
	width: 100%;
}
.logo-footer img{
    width: 20vw;
    max-width: 200px;
    height: 50%;
    width: 50%;
    margin: auto;
    padding-bottom: 1vw;
}

.log-footer-container h1{
	white-space: nowrap;

}

.contact-details-footer{
	padding-left: 20px;
	width: 60%;
	text-align: center;
	
}
.contact-details-heading{
	text-align: center;
	height: 15%;
	margin-top: 25%;
}

.contact-details-footer p{
	margin: auto;
	height : 10%;

}

.address-footer{
	width: 40%;
	margin-left: 2.5%;
}
.address-div{
	padding-top: 10px;
}

.slideshow-div{
 

}

.contact-us-div{
display: flex;
flex-direction: row;
min-height: 50px;
height: 80vh;
color: white;
}
.contact-us-image{
	width: 50%;
	padding: 1vw;
	background-position: center;
	text-align: center;

}

.form-container{
	display: flex;
    flex-direction: column;
	width: 50%;   
	margin-top: 1vh;
   
    
}

.forMobile{
	display: none;
}

.form-container input{
	padding: 1vw;
	margin : auto;
	width: 90%;
	border-top: 0px;
	border-right: 0px;
	border-left: 0px;
	height: 3vh;
	outline: none;

	
}

.form-container button {
	align-self: center;
	width: 25%;
	height: 10%;
	background-color: #fce04c;
	border-radius: 3px;
	border:none;
	margin-bottom: 2vh;

}

.form-container textarea{
	height: 20%;
	padding: 5px;
	width: 90%;
	margin : auto;
	border-radius: 5px;
}

.recaptcha{
	margin:auto;
	
	align-content: center;
	display: flex;
}

.g-recaptcha {
        width: 80%;
        height: 100%;
    }

.Icon{
font-weight: 100;
font-size: 3vw;
}

@media screen and (max-width: 560px){
	
	.MenuButton{

	}

	.form-container{
	width:100%;

	 
   	}
   	.contact-us-image{
	width : 30%;
	display: none;
	font-size: 1em;

	}
	
	.footer{
		display: flex;
		flex-direction: column;
		padding-top: 1vh;
	}

	.footer1{
		width: 100%;
	}
	.address-footer{
	width: 100%;
	margin: auto;
	padding : 5px;
	text-align: center;

	}

	.Icon{
		font-size: 6vw;
	}

	.recaptcha{

	margin: auto;
	width: 75%;
	}
	.forMobile{
		display: block;
		color: black;
		text-align: center;
	}
   
}


.IconButton {
	border : none;
	background-color: transparent;
	height: 100%;

}

.IconButton:hover{

}



.Header_Search{
	background-color: transparent;
}

.searchBar-container{
.IconButton:hover{
	background-color: #ebc80c;

}
}

.AboutUsObjective{
	padding-top : 10vh;
	padding-bottom: 8vh;
	padding-left: 2vw;
	padding-right: 2vw;
}

.AboutUsIntro{
	display: flex;
	flex-direction: row;

}

.AboutUsText{
	width: 50%;
	margin: auto;
	padding-left: 5vw;
}
.AboutUsImg{
	margin: auto;

}

.AboutUsImg img{
	height: 25em;
	width: 25em;
}

.AboutUsImg img{
 height: 100%;

}

.AboutUsInHouse{
	display: flex;
	flex-direction: row;
}

.AboutUsTimeline{
	text-align: center;
	padding-top: 9vh;
	padding-bottom: 5vh;
}



@media screen and (max-width: 560px){
	
	.AboutUsObjective{
		padding-top: 4vh;
		padding-bottom: 3vh;
	}
	.AboutUsIntro{
		display: flex;
		flex-direction: column-reverse;
	}

	.AboutUsText{
	width: 100%;

	}

	.AboutUsInHouse{
		display: flex;
		flex-direction: column;
	}

}

.item-wrapper{
	display: flex;
	flex-direction: row;

}

.FacilitiesImg{
 	margin: auto;
 	height: 30vh;
 	

}

.FacilitiesImg img{
	height: 100%;
	width: 100%;
}

.text{
	padding:5vw;
	width: 50%;
}

.item-wrapper1{
	display: flex;
	flex-direction: row-reverse;

}

@media screen and (max-width: 560px){
	.item-wrapper{
		flex-direction: column;

	}

	.item-wrapper1{
	flex-direction: column;
	
}

.text{
		width: 100%;
	}

}

.BrandContainer{
	display: flex;
	direction: row;
	flex-wrap: wrap;
	padding-bottom: 5vw;
	
}
.Brand{
	height: 30vh;
	width: 30vw;
	min-height: 150px;
	min-width: 150px;
	padding: 3vw;
	margin : auto;
	margin-top : 3vw;
	border-style: solid;


}

.Brand p{
	text-align: center;
}
.ImgContainer{
	height: 20vh;
	width: 20vw;
	margin : auto;

}	
.ImgContainer img{
	display: block;
	height: 90%;
	width : 90%;
	margin : auto;
}







.login-container{
  margin-right: auto;
  margin-left: auto;
  margin-top: 15%;
  margin-bottom: 15%;
  width: 60%;
  height: 80%;

  background-color: 'blue';
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06),0 1px 2px rgba(0, 0, 0, 0.12);
  border-style: solid;
  padding: 10px 10px;

  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.title{
font-size: 5vw;
margin: auto;
border-bottom: solid;
}

.login-label{
  margin: auto;
  flex: auto;
  font-size: 3vw;
}

.login-button{
  background-color: lightgrey; 

  align-self: flex-end;
  width: 20%;
  border-radius:10px;
  box-shadow: 1px 1px 0px 2px;
  cursor: pointer;
  aspect-ratio : 2/1;
  font-size: 2vw;
 }

.logout-button{
  background-color: red;
  border-radius:10px;
  
  
  box-shadow: 1px 1px 0px 2px;

}

.Input-box{
  width: 50%;
  aspect-ratio : 8/1;
  font-size: 2.5vw;
}

.Item {
	width: 100%;
	
}

.Item h2{
	margin-top: 5vh;
	margin-bottom: 2vh;
	border-width: 1px;
	
	font-size: 2em;
    background-color: #fce04c;
	
	padding: 2vw;

}

.Item-Details{
	display: flex;
	flex-direction: row;
	
}

.Item-Details img{
	width: 50%;
	height: 100%;
	aspect-ratio : 1;
}



.Item-Data {
	width: 50%;
	display: flex;
	flex-direction: row;
	margin-left:5vw;

}

.Item-list{
	width: 50%;
}

.Item-list p{
	height: 25%;
	font-size: 1.5em;

}
.Item-not-found{
	display: flex;
	flex-direction: row;

}
.Item-not-found h1{
	margin: auto;
}

@media screen and (max-width: 560px){
	.Item-Details{
		display: block;
	}
	.Item-Details img{
		width : 60%;
		aspect-ratio : 1;
		align-self: center;
		
	}
	.Item-Data{
		padding-top: 1vh;
		width: 100%;
	}
	.Item-list{
		text-align: center;
	}

	.Item-list p{
	height: 25%;
	}
	
}

.formSent {
	color : black;
	text-align: center;
	margin: auto;
}

.formSent svg{
	height: 5em;
	width: 5em;
}

.BackToContactUs h2{
	margin:auto;
	padding-top : 5vh;
	padding-bottom: 6vh;
	padding-left: 6vw; 
}
