.searchBar-container{
	margin:auto;
	display: flex;
	background-color: transparent;
	flex-direction: row;
	width: 30vw;
	max-width: 200px;
	padding: 10px;
	height: 5vh;
	border-bottom-width:2px;
	border-top: 0px;
	border-right: 0px;
	border-left :0px;
	border-style:solid;
	border-color:black;

}

.Header_Search{
	width: 90%;
	border-top: 0px;
	border-right: 0px;
	border-left: 0px;
	border-bottom : 0px;
	margin : auto;
	height: 100%;
	outline: none;
	background-color: transparent;
	font-size: 1rem;
}




@media screen and (max-width: 560px){
	.searchBar-container{
		width : 50vw;

	}

}

